(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.f1.g1 === region.f9.g1)
	{
		return 'on line ' + region.f1.g1;
	}
	return 'on lines ' + region.f1.g1 + ' through ' + region.f9.g1;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.g$,
		impl.hl,
		impl.hi,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		g4: func(record.g4),
		f2: record.f2,
		fY: record.fY
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.g4;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.f2;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.fY) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.g$,
		impl.hl,
		impl.hi,
		function(sendToApp, initialModel) {
			var view = impl.hm;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.g$,
		impl.hl,
		impl.hi,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fZ && impl.fZ(sendToApp)
			var view = impl.hm;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.dM);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hj) && (_VirtualDom_doc.title = title = doc.hj);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.g7;
	var onUrlRequest = impl.g8;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fZ: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.gw === next.gw
							&& curr.gh === next.gh
							&& curr.gr.a === next.gr.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		g$: function(flags)
		{
			return A3(impl.g$, flags, _Browser_getUrl(), key);
		},
		hm: impl.hm,
		hl: impl.hl,
		hi: impl.hi
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { gY: 'hidden', N: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { gY: 'mozHidden', N: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { gY: 'msHidden', N: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { gY: 'webkitHidden', N: 'webkitvisibilitychange' }
		: { gY: 'hidden', N: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		gD: _Browser_getScene(),
		gL: {
			fL: _Browser_window.pageXOffset,
			fM: _Browser_window.pageYOffset,
			gM: _Browser_doc.documentElement.clientWidth,
			gf: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		gM: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		gf: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			gD: {
				gM: node.scrollWidth,
				gf: node.scrollHeight
			},
			gL: {
				fL: node.scrollLeft,
				fM: node.scrollTop,
				gM: node.clientWidth,
				gf: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			gD: _Browser_getScene(),
			gL: {
				fL: x,
				fM: y,
				gM: _Browser_doc.documentElement.clientWidth,
				gf: _Browser_doc.documentElement.clientHeight
			},
			gV: {
				fL: x + rect.left,
				fM: y + rect.top,
				gM: rect.width,
				gf: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.ef.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.ef.b, xhr)); });
		$elm$core$Maybe$isJust(request.gJ) && _Http_track(router, xhr, request.gJ.a);

		try {
			xhr.open(request.dN, request.dQ, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.dQ));
		}

		_Http_configureRequest(xhr, request);

		request.dM.a && xhr.setRequestHeader('Content-Type', request.dM.a);
		xhr.send(request.dM.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.cN; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.cP.a || 0;
	xhr.responseType = request.ef.d;
	xhr.withCredentials = request.gO;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		dQ: xhr.responseURL,
		hf: xhr.status,
		hg: xhr.statusText,
		cN: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			he: event.loaded,
			gF: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			ha: event.loaded,
			gF: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.g5) { flags += 'm'; }
	if (options.gQ) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.cp) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.cG),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.cG);
		} else {
			var treeLen = builder.cp * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.cI) : builder.cI;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.cp);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.cG) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.cG);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{cI: nodeList, cp: (len / $elm$core$Array$branchFactor) | 0, cG: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ge: fragment, gh: host, gp: path, gr: port_, gw: protocol, gx: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$GotResponse = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return {
			e3: baseUrl,
			fP: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
			ef: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
			cN: _List_Nil,
			eK: $elm$core$Maybe$Nothing,
			dU: _List_Nil,
			cP: $elm$core$Maybe$Nothing,
			dY: false
		};
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {gz: reqs, gH: subs};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.gJ;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.gz));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.gH)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					gO: r.gO,
					dM: r.dM,
					ef: A2(_Http_mapExpect, func, r.ef),
					cN: r.cN,
					dN: r.dN,
					cP: r.cP,
					gJ: r.gJ,
					dQ: r.dQ
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{gO: false, dM: r.dM, ef: r.ef, cN: r.cN, dN: r.dN, cP: r.cP, gJ: r.gJ, dQ: r.dQ}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{gO: true, dM: r.dM, ef: r.ef, cN: r.cN, dN: r.dN, cP: r.cP, gJ: r.gJ, dQ: r.dQ}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (!httpResult.$) {
		var successOrError = httpResult.a;
		if (!successOrError.$) {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 2};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 1:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 2:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 3:
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$Basics$ge = _Utils_ge;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.g3;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{cI: nodeList, cp: nodeListSize, cG: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {g_: index, g3: match, g6: number, hh: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{gQ: false, g5: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {d$: charsProcessed, ej: hash, dW: seed, eQ: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.ej)) ? (data.dW ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.ej)))) : data.dW;
	var h0 = acc ^ data.d$;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.ej | ((255 & $elm$core$Char$toCode(c)) << data.eQ);
		var _v0 = data.eQ;
		if (_v0 === 24) {
			return {
				d$: data.d$ + 1,
				ej: 0,
				dW: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.dW, res),
				eQ: 0
			};
		} else {
			return {d$: data.d$ + 1, ej: res, dW: data.dW, eQ: data.eQ + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	switch (value.$) {
		case 0:
			var enumValue = value.a;
			return enumValue;
		case 1:
			var json = value.a;
			return A2($elm$json$Json$Encode$encode, 0, json);
		case 2:
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (!field.$) {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.hk;
				var fieldName = field.a.gc;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (!field.$) {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.hk;
		var fieldName = field.a.gc;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces = function (n) {
	return (n > 0) ? (' ' + $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(n - 1)) : '';
};
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(indentationLevel * 2);
};
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{gc: '__typename', hk: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F3(
	function (aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (!aliasName.$) {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (!mIndentationLevel.$) {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (!field.$) {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 1) {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A2(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.gc;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (!indentationLevel.$) {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (field) {
						return A3(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field),
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(children))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (!maybeOperationName.$) {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('query', serializedQueryForGetRequest)
						]),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(1)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(0))))) {
			var _v2 = function () {
				if (!maybeOperationName.$) {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				dM: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'query',
									$elm$json$Json$Encode$string(serializedQuery))
								]),
							operationNameParamForPostRequest))),
				dN: 1,
				dQ: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, _List_Nil, url)
			};
		} else {
			return {dM: $elm$http$Http$emptyBody, dN: 0, dQ: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {fP: details, g2: locations, g4: message};
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {gS: column, g1: line};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (!_v0.$) {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = 0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0;
	var _v1 = request.fP;
	if (!_v1.$) {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (!forcedRequestMethod.$) {
					if (!forcedRequestMethod.a) {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just(0);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just(1);
				}
			}(),
			request.e3,
			request.dU,
			request.eK,
			querySelectionSet);
		return {
			dM: queryRequestDetails.dM,
			dR: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.ef),
			cN: request.cN,
			dN: function () {
				var _v2 = queryRequestDetails.dN;
				if (!_v2) {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			cP: request.cP,
			dQ: queryRequestDetails.dQ
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v6 = request.eK;
			if (!_v6.$) {
				var operationName = _v6.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			dM: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'query',
							$elm$json$Json$Encode$string(serializedMutation))
						]))),
			dR: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.ef),
			cN: request.cN,
			dN: 'POST',
			cP: request.cP,
			dQ: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.dU, request.e3)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return function (readyRequest) {
			return {
				dM: readyRequest.dM,
				ef: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.dR),
				cN: readyRequest.cN,
				dN: readyRequest.dN,
				cP: readyRequest.cP,
				gJ: $elm$core$Maybe$Nothing,
				dQ: readyRequest.dQ
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return (request.dY ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $dillonkearns$elm_graphql$Graphql$Http$withHeader = F3(
	function (key, value, _v0) {
		var request = _v0;
		return _Utils_update(
			request,
			{
				cN: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, key, value),
					request.cN)
			});
	});
var $author$project$ServerData$withAuthHeader = F2(
	function (token, r) {
		if (!token.$) {
			var t = token.a;
			return A3($dillonkearns$elm_graphql$Graphql$Http$withHeader, 'authorization', 'Bearer ' + t, r);
		} else {
			return r;
		}
	});
var $author$project$ServerData$executeQuery = F3(
	function (token, q, msg) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg),
			A2(
				$author$project$ServerData$withAuthHeader,
				token,
				A2($dillonkearns$elm_graphql$Graphql$Http$queryRequest, '/graphql', q)));
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$AppendModal$init = function () {
	var v = 1;
	return {
		fb: false,
		eX: $elm$core$String$fromFloat(v),
		A: v
	};
}();
var $author$project$Api$Enum$ProductCategoriesOrderBy$NameAsc = 3;
var $author$project$Api$Enum$ProductsOrderBy$NameAsc = 5;
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 0, a: a};
};
var $author$project$ServerData$Response = F4(
	function (products, categories, items, warehouses) {
		return {gR: categories, c3: items, gv: products, hn: warehouses};
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 1};
var $author$project$Api$Scalar$BigFloat = $elm$core$Basics$identity;
var $author$project$Api$Scalar$BigInt = $elm$core$Basics$identity;
var $author$project$Api$Scalar$Cursor = $elm$core$Basics$identity;
var $author$project$Api$Scalar$Date = $elm$core$Basics$identity;
var $author$project$Api$Scalar$Datetime = $elm$core$Basics$identity;
var $author$project$Api$Scalar$Id = $elm$core$Basics$identity;
var $author$project$Api$Scalar$Json = $elm$core$Basics$identity;
var $author$project$Api$Scalar$JwtToken = $elm$core$Basics$identity;
var $author$project$Api$Scalar$Uuid = $elm$core$Basics$identity;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				if (bool) {
					return 'true';
				} else {
					return 'false';
				}
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Api$Scalar$defaultCodecs = {
	o: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v0) {
			var raw = _v0;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	cK: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v1) {
			var raw = _v1;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	aX: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v2) {
			var raw = _v2;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	c1: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v3) {
			var raw = _v3;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	F: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v4) {
			var raw = _v4;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	K: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v5) {
			var raw = _v5;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	aQ: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v6) {
			var raw = _v6;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	f7: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v7) {
			var raw = _v7;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	cJ: {
		dR: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		db: function (_v8) {
			var raw = _v8;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Api$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Api$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $author$project$Api$ScalarCodecs$codecs = $author$project$Api$Scalar$defineCodecs(
	{o: $author$project$Api$Scalar$defaultCodecs.o, cK: $author$project$Api$Scalar$defaultCodecs.cK, aX: $author$project$Api$Scalar$defaultCodecs.aX, c1: $author$project$Api$Scalar$defaultCodecs.c1, F: $author$project$Api$Scalar$defaultCodecs.F, K: $author$project$Api$Scalar$defaultCodecs.K, aQ: $author$project$Api$Scalar$defaultCodecs.aQ, f7: $author$project$Api$Scalar$defaultCodecs.f7, cJ: $author$project$Api$Scalar$defaultCodecs.cJ});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$int = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$int(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				if (!value.$) {
					var actualValue = value.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(key, actualValue));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			maybeValues));
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2(
	function (optionalValue, toValue) {
		switch (optionalValue.$) {
			case 0:
				var value = optionalValue.a;
				return $elm$core$Maybe$Just(
					toValue(value));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Api$InputObject$encodeProductCategoryCondition = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'position',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.G, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$bool(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$list = F2(
	function (toValue, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$List(
			A2($elm$core$List$map, toValue, value));
	});
var $author$project$Api$InputObject$encodeIntFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.s, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'notEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.z, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'distinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.r, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$int))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$int))),
				_Utils_Tuple2(
				'lessThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.w, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.x, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'greaterThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.u, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Api$InputObject$encodeStringFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.s, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.z, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'distinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.r, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'lessThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.w, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.x, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'greaterThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.u, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'includes',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.en, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notIncludes',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eE, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'includesInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eo, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notIncludesInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eF, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'startsWith',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eT, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notStartsWith',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eI, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'startsWithInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eU, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notStartsWithInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eJ, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'endsWith',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eb, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notEndsWith',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eA, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'endsWithInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ec, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notEndsWithInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eB, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'like',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eu, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notLike',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eG, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'likeInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ev, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notLikeInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eH, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'equalToInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ed, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notEqualToInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eC, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'distinctFromInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.d9, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'notDistinctFromInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ez, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'inInsensitive',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.em,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'notInInsensitive',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.eD,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'lessThanInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.es, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'lessThanOrEqualToInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.et, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'greaterThanInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eh, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'greaterThanOrEqualToInsensitive',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ei, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Api$InputObject$encodeProductCategoryFilter = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $author$project$Api$InputObject$encodeStringFilter)),
				_Utils_Tuple2(
				'position',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.G, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeProductCategoryFilter))),
				_Utils_Tuple2(
				'or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeProductCategoryFilter))),
				_Utils_Tuple2(
				'not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $author$project$Api$InputObject$encodeProductCategoryFilter))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 0:
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
				decoderTransform(decoder)));
	});
var $author$project$Api$Enum$ProductCategoriesOrderBy$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'NATURAL';
		case 1:
			return 'ID_ASC';
		case 2:
			return 'ID_DESC';
		case 3:
			return 'NAME_ASC';
		case 4:
			return 'NAME_DESC';
		case 5:
			return 'POSITION_ASC';
		case 6:
			return 'POSITION_DESC';
		case 7:
			return 'PRIMARY_KEY_ASC';
		default:
			return 'PRIMARY_KEY_DESC';
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Api$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).db,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Api$Query$allProductCategories = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ab: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ac: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ad: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ae: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, af: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ag: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ah: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, fX: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'first', filledInOptionals____.af, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'last', filledInOptionals____.ag, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.ah, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'before',
					filledInOptionals____.ac,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'after',
					filledInOptionals____.ab,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'orderBy',
					filledInOptionals____.fX,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductCategoriesOrderBy$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'condition', filledInOptionals____.ad, $author$project$Api$InputObject$encodeProductCategoryCondition),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'filter', filledInOptionals____.ae, $author$project$Api$InputObject$encodeProductCategoryFilter)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'allProductCategories',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $elm$json$Json$Encode$float = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$float = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$float(value));
};
var $author$project$Api$InputObject$encodeIntervalInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'seconds',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eO, $dillonkearns$elm_graphql$Graphql$Internal$Encode$float)),
				_Utils_Tuple2(
				'minutes',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ex, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'hours',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.el, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'days',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.d5, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'months',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ey, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'years',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.e$, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
};
var $author$project$Api$Enum$ProductExternalStatus$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'AVAILABLE';
		case 1:
			return 'BY_REQUEST';
		case 2:
			return 'HIDDEN';
		case 3:
			return 'FROM_PARENT';
		default:
			return 'ALWAYS_AVAILABLE';
	}
};
var $author$project$Api$Enum$ProductStatus$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'ACTIVE';
		case 1:
			return 'DEPRECATED';
		default:
			return 'ARCHIVE';
	}
};
var $author$project$Api$Enum$ProductUnit$toString = function (enum____) {
	if (!enum____) {
		return 'KG';
	} else {
		return 'PCS';
	}
};
var $author$project$Api$InputObject$encodeProductCondition = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'parentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bJ, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'barcodes',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a7,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'unit',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b5,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'status',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.E,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'pcsWeight',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bL,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'shelfLife',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bT, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'meta',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.q,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'packingTolerance',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bI,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'requiresPackaging',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bP, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'ownPackaging',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bG, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'tags',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b_,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'categoryId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.M, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'ownProduction',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bH, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'externalStatus',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bj,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'availableQuantityThreshold',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a6,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'country',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.be, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'syncId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bX,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'vat',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ca, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Api$InputObject$encodeBigFloatFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Api$Scalar$unwrapEncoder,
							function ($) {
								return $.o;
							},
							$author$project$Api$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Api$Scalar$unwrapEncoder,
							function ($) {
								return $.o;
							},
							$author$project$Api$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs)))
			]));
};
var $author$project$Api$InputObject$encodeBigFloatListFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'contains',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bc,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'containedBy',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bb,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'overlaps',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cC,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe(
							A2(
								$author$project$Api$Scalar$unwrapEncoder,
								function ($) {
									return $.o;
								},
								$author$project$Api$ScalarCodecs$codecs))))),
				_Utils_Tuple2(
				'anyEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cr,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'anyNotEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cw,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'anyLessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cu,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'anyLessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cv,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'anyGreaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cs,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'anyGreaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ct,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs)))
			]));
};
var $author$project$Api$InputObject$encodeBooleanFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.s, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'notEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.z, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'distinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.r, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))),
				_Utils_Tuple2(
				'lessThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.w, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.x, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'greaterThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.u, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))
			]));
};
var $author$project$Api$InputObject$encodeIntervalFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.s, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'notEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.z, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'distinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.r, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeIntervalInput))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeIntervalInput))),
				_Utils_Tuple2(
				'lessThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.w, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.x, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'greaterThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Api$InputObject$encodeIntervalInput)),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.u, $author$project$Api$InputObject$encodeIntervalInput))
			]));
};
var $author$project$Api$InputObject$encodeJSONFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Api$Scalar$unwrapEncoder,
							function ($) {
								return $.aQ;
							},
							$author$project$Api$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Api$Scalar$unwrapEncoder,
							function ($) {
								return $.aQ;
							},
							$author$project$Api$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'contains',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bc,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'containsKey',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.d3, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'containsAllKeys',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d1,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'containsAnyKeys',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d2,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'containedBy',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bb,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs)))
			]));
};
var $author$project$Api$InputObject$encodeProductExternalStatusFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductExternalStatus$toString)))
			]));
};
var $author$project$Api$InputObject$encodeProductStatusFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductStatus$toString)))
			]));
};
var $author$project$Api$InputObject$encodeProductUnitFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductUnit$toString)))
			]));
};
var $author$project$Api$InputObject$encodeStringListFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'contains',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bc,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'containedBy',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bb,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'overlaps',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cC,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$string)))),
				_Utils_Tuple2(
				'anyEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cr, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'anyNotEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cw, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'anyLessThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cu, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'anyLessThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cv, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'anyGreaterThan',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cs, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'anyGreaterThanOrEqualTo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ct, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Api$InputObject$encodeUUIDFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Api$Scalar$unwrapEncoder,
							function ($) {
								return $.cJ;
							},
							$author$project$Api$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Api$Scalar$unwrapEncoder,
							function ($) {
								return $.cJ;
							},
							$author$project$Api$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.cJ;
						},
						$author$project$Api$ScalarCodecs$codecs)))
			]));
};
var $author$project$Api$InputObject$encodeProductFilter = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'parentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bJ, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $author$project$Api$InputObject$encodeStringFilter)),
				_Utils_Tuple2(
				'barcodes',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a7, $author$project$Api$InputObject$encodeStringListFilter)),
				_Utils_Tuple2(
				'unit',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b5, $author$project$Api$InputObject$encodeProductUnitFilter)),
				_Utils_Tuple2(
				'status',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.E, $author$project$Api$InputObject$encodeProductStatusFilter)),
				_Utils_Tuple2(
				'pcsWeight',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bL, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'shelfLife',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bT, $author$project$Api$InputObject$encodeIntervalFilter)),
				_Utils_Tuple2(
				'meta',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Api$InputObject$encodeJSONFilter)),
				_Utils_Tuple2(
				'packingTolerance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bI, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'requiresPackaging',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bP, $author$project$Api$InputObject$encodeBooleanFilter)),
				_Utils_Tuple2(
				'ownPackaging',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bG, $author$project$Api$InputObject$encodeBooleanFilter)),
				_Utils_Tuple2(
				'tags',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b_, $author$project$Api$InputObject$encodeStringListFilter)),
				_Utils_Tuple2(
				'categoryId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.M, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'ownProduction',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bH, $author$project$Api$InputObject$encodeBooleanFilter)),
				_Utils_Tuple2(
				'externalStatus',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bj, $author$project$Api$InputObject$encodeProductExternalStatusFilter)),
				_Utils_Tuple2(
				'availableQuantityThreshold',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a6, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'country',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.be, $author$project$Api$InputObject$encodeStringFilter)),
				_Utils_Tuple2(
				'syncId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bX, $author$project$Api$InputObject$encodeUUIDFilter)),
				_Utils_Tuple2(
				'vat',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ca, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'shopOrderPrices',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.eR, $author$project$Api$InputObject$encodeBigFloatListFilter)),
				_Utils_Tuple2(
				'and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeProductFilter))),
				_Utils_Tuple2(
				'or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeProductFilter))),
				_Utils_Tuple2(
				'not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $author$project$Api$InputObject$encodeProductFilter))
			]));
};
var $author$project$Api$Enum$ProductsOrderBy$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'NATURAL';
		case 1:
			return 'ID_ASC';
		case 2:
			return 'ID_DESC';
		case 3:
			return 'PARENT_ID_ASC';
		case 4:
			return 'PARENT_ID_DESC';
		case 5:
			return 'NAME_ASC';
		case 6:
			return 'NAME_DESC';
		case 7:
			return 'BARCODES_ASC';
		case 8:
			return 'BARCODES_DESC';
		case 9:
			return 'UNIT_ASC';
		case 10:
			return 'UNIT_DESC';
		case 11:
			return 'STATUS_ASC';
		case 12:
			return 'STATUS_DESC';
		case 13:
			return 'PCS_WEIGHT_ASC';
		case 14:
			return 'PCS_WEIGHT_DESC';
		case 15:
			return 'SHELF_LIFE_ASC';
		case 16:
			return 'SHELF_LIFE_DESC';
		case 17:
			return 'META_ASC';
		case 18:
			return 'META_DESC';
		case 19:
			return 'PACKING_TOLERANCE_ASC';
		case 20:
			return 'PACKING_TOLERANCE_DESC';
		case 21:
			return 'REQUIRES_PACKAGING_ASC';
		case 22:
			return 'REQUIRES_PACKAGING_DESC';
		case 23:
			return 'OWN_PACKAGING_ASC';
		case 24:
			return 'OWN_PACKAGING_DESC';
		case 25:
			return 'TAGS_ASC';
		case 26:
			return 'TAGS_DESC';
		case 27:
			return 'CATEGORY_ID_ASC';
		case 28:
			return 'CATEGORY_ID_DESC';
		case 29:
			return 'OWN_PRODUCTION_ASC';
		case 30:
			return 'OWN_PRODUCTION_DESC';
		case 31:
			return 'EXTERNAL_STATUS_ASC';
		case 32:
			return 'EXTERNAL_STATUS_DESC';
		case 33:
			return 'AVAILABLE_QUANTITY_THRESHOLD_ASC';
		case 34:
			return 'AVAILABLE_QUANTITY_THRESHOLD_DESC';
		case 35:
			return 'COUNTRY_ASC';
		case 36:
			return 'COUNTRY_DESC';
		case 37:
			return 'SYNC_ID_ASC';
		case 38:
			return 'SYNC_ID_DESC';
		case 39:
			return 'VAT_ASC';
		case 40:
			return 'VAT_DESC';
		case 41:
			return 'PRIMARY_KEY_ASC';
		default:
			return 'PRIMARY_KEY_DESC';
	}
};
var $author$project$Api$Query$allProducts = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ab: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ac: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ad: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ae: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, af: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ag: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ah: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, fX: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'first', filledInOptionals____.af, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'last', filledInOptionals____.ag, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.ah, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'before',
					filledInOptionals____.ac,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'after',
					filledInOptionals____.ab,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'orderBy',
					filledInOptionals____.fX,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductsOrderBy$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'condition', filledInOptionals____.ad, $author$project$Api$InputObject$encodeProductCondition),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'filter', filledInOptionals____.ae, $author$project$Api$InputObject$encodeProductFilter)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'allProducts',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Api$InputObject$encodeWarehouseCondition = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Api$InputObject$encodeWarehouseFilter = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $author$project$Api$InputObject$encodeStringFilter)),
				_Utils_Tuple2(
				'and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeWarehouseFilter))),
				_Utils_Tuple2(
				'or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeWarehouseFilter))),
				_Utils_Tuple2(
				'not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $author$project$Api$InputObject$encodeWarehouseFilter))
			]));
};
var $author$project$Api$Enum$WarehousesOrderBy$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'NATURAL';
		case 1:
			return 'ID_ASC';
		case 2:
			return 'ID_DESC';
		case 3:
			return 'NAME_ASC';
		case 4:
			return 'NAME_DESC';
		case 5:
			return 'PRIMARY_KEY_ASC';
		default:
			return 'PRIMARY_KEY_DESC';
	}
};
var $author$project$Api$Query$allWarehouses = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ab: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ac: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ad: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ae: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, af: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ag: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ah: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, fX: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'first', filledInOptionals____.af, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'last', filledInOptionals____.ag, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.ah, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'before',
					filledInOptionals____.ac,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'after',
					filledInOptionals____.ab,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'orderBy',
					filledInOptionals____.fX,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$WarehousesOrderBy$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'condition', filledInOptionals____.ad, $author$project$Api$InputObject$encodeWarehouseCondition),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'filter', filledInOptionals____.ae, $author$project$Api$InputObject$encodeWarehouseFilter)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'allWarehouses',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$ServerData$Category = F2(
	function (rowId, name) {
		return {j: name, b: rowId};
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map2 = F3(
	function (combine, _v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, combine, selectionDecoder1, selectionDecoder2));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{gc: fieldName, hk: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
				decoder));
	});
var $author$project$Api$Object$ProductCategory$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$ProductCategory$rowId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'rowId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$ServerData$categorySelection = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$ServerData$Category, $author$project$Api$Object$ProductCategory$rowId, $author$project$Api$Object$ProductCategory$name);
var $author$project$Api$Object$ProductCategoriesConnection$nodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'nodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$ServerData$categoriesSelection = $author$project$Api$Object$ProductCategoriesConnection$nodes($author$project$ServerData$categorySelection);
var $author$project$Api$InputObject$encodeAllPackingWarehouseShopOrderItemsConditionInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'categoryIds',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d_,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$maybe($dillonkearns$elm_graphql$Graphql$Internal$Encode$int)))),
				_Utils_Tuple2(
				'day',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cx,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.c1;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'warehouseId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Api$Enum$ShopOrderItemReturnStatus$toString = function (enum____) {
	if (!enum____) {
		return 'THROW';
	} else {
		return 'REUSE';
	}
};
var $author$project$Api$InputObject$encodeShopOrderItemReturnStatusFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'isNull',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'equalTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'notEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.z,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'distinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'notDistinctFrom',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString)))),
				_Utils_Tuple2(
				'notIn',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.D,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString)))),
				_Utils_Tuple2(
				'lessThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'lessThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.x,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'greaterThan',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.t,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'greaterThanOrEqualTo',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString)))
			]));
};
var $author$project$Api$InputObject$encodeShopOrderItemFilter = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'documentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.l, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'warehouseId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'productId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.f, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'orderedQuantity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aN, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'prepareQuantity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aw, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'quantity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.k, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'price',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'priceMultiplier',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ax, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'meta',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Api$InputObject$encodeJSONFilter)),
				_Utils_Tuple2(
				'printed',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.I, $author$project$Api$InputObject$encodeBooleanFilter)),
				_Utils_Tuple2(
				'comment',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.e, $author$project$Api$InputObject$encodeStringFilter)),
				_Utils_Tuple2(
				'deficit',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $author$project$Api$InputObject$encodeBooleanFilter)),
				_Utils_Tuple2(
				'weightMultiplier',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aK, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'supplyProductionItemId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aC, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'returnStatus',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.az, $author$project$Api$InputObject$encodeShopOrderItemReturnStatusFilter)),
				_Utils_Tuple2(
				'supplyOrderedQuantity',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aB, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'supplyDocumentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'totalSum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aS, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeShopOrderItemFilter))),
				_Utils_Tuple2(
				'or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeShopOrderItemFilter))),
				_Utils_Tuple2(
				'not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $author$project$Api$InputObject$encodeShopOrderItemFilter))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3(
	function (fieldName, raw, encode) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
			fieldName,
			encode(raw));
	});
var $author$project$Api$Query$allPackingWarehouseShopOrderItems = F3(
	function (fillInOptionals____, requiredArgs____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ab: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ac: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ae: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, af: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ag: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ah: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'first', filledInOptionals____.af, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'last', filledInOptionals____.ag, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.ah, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'before',
					filledInOptionals____.ac,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'after',
					filledInOptionals____.ab,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'filter', filledInOptionals____.ae, $author$project$Api$InputObject$encodeShopOrderItemFilter)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'allPackingWarehouseShopOrderItems',
			_Utils_ap(
				optionalArgs____,
				_List_fromArray(
					[
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'params', requiredArgs____.go, $author$project$Api$InputObject$encodeAllPackingWarehouseShopOrderItemsConditionInput)
					])),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$ServerData$Item = F8(
	function (rowId, orderedQuantity, prepareQuantity, document, productId, comment, documentId, warehouseId) {
		return {e: comment, cR: document, l: documentId, aN: orderedQuantity, aw: prepareQuantity, f: productId, b: rowId, n: warehouseId};
	});
var $author$project$Api$Object$ShopOrderItem$comment = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'comment',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Object$ShopOrderItem$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'documentId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$ServerData$Document = F8(
	function (hour, groupId, owner, asap, comment, ecoPack, withoutOverweight, status) {
		return {ai: asap, e: comment, at: ecoPack, S: groupId, fc: hour, R: owner, E: status, aM: withoutOverweight};
	});
var $author$project$Api$Object$ShopOrderDocument$asap = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'asap', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$ShopOrderDocument$comment = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'comment', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0;
	return unwrappedCodecs;
};
var $author$project$Api$Object$ShopOrderDocument$deliveryAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Datetime)',
	'deliveryAt',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).F.dR));
var $author$project$Api$Object$ShopOrderDocument$ecoPack = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'ecoPack', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$Object$ShopOrderDocument$groupId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'groupId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2(
	function (mapFunction, _v0) {
		var selectionFields = _v0.a;
		var selectionDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			selectionFields,
			A2($elm$json$Json$Decode$map, mapFunction, selectionDecoder));
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$json$Json$Decode$map8 = _Json_map8;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map8 = F9(
	function (combine, _v0, _v1, _v2, _v3, _v4, _v5, _v6, _v7) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		var selectionFields5 = _v4.a;
		var selectionDecoder5 = _v4.b;
		var selectionFields6 = _v5.a;
		var selectionDecoder6 = _v5.b;
		var selectionFields7 = _v6.a;
		var selectionDecoder7 = _v6.b;
		var selectionFields8 = _v7.a;
		var selectionDecoder8 = _v7.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4, selectionFields5, selectionFields6, selectionFields7, selectionFields8])),
			A9($elm$json$Json$Decode$map8, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4, selectionDecoder5, selectionDecoder6, selectionDecoder7, selectionDecoder8));
	});
var $elm$json$Json$Decode$fail = _Json_fail;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail = function (_v0) {
	var fields = _v0.a;
	var decoder = _v0.b;
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		fields,
		A2(
			$elm$json$Json$Decode$andThen,
			function (result) {
				if (!result.$) {
					var value = result.a;
					return $elm$json$Json$Decode$succeed(value);
				} else {
					return $elm$json$Json$Decode$fail('Expected non-null but got null, check for calls to nonNullOrFail in your code. Ideally your schema should indicate that this is non-nullable so you don\'t need to use nonNullOrFail at all.');
				}
			},
			decoder));
};
var $author$project$Api$Object$ShopOrderDocument$owner = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'owner',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$Enum$ShopOrderDocumentStatus$Canceled = 3;
var $author$project$Api$Enum$ShopOrderDocumentStatus$Completed = 4;
var $author$project$Api$Enum$ShopOrderDocumentStatus$Delivered = 5;
var $author$project$Api$Enum$ShopOrderDocumentStatus$Packing = 1;
var $author$project$Api$Enum$ShopOrderDocumentStatus$Pending = 0;
var $author$project$Api$Enum$ShopOrderDocumentStatus$Shipped = 2;
var $author$project$Api$Enum$ShopOrderDocumentStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'PENDING':
				return $elm$json$Json$Decode$succeed(0);
			case 'PACKING':
				return $elm$json$Json$Decode$succeed(1);
			case 'SHIPPED':
				return $elm$json$Json$Decode$succeed(2);
			case 'CANCELED':
				return $elm$json$Json$Decode$succeed(3);
			case 'COMPLETED':
				return $elm$json$Json$Decode$succeed(4);
			case 'DELIVERED':
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail('Invalid ShopOrderDocumentStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$ShopOrderDocument$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.ShopOrderDocumentStatus.ShopOrderDocumentStatus', 'status', _List_Nil, $author$project$Api$Enum$ShopOrderDocumentStatus$decoder);
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.f1, posixMinutes) < 0) {
					return posixMinutes + era.ah;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {f8: col, gT: contextStack, gs: problem, gC: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.gC, s.f8, x, s.aY));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.Y),
			s.ah) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.Y);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.ah, offset) < 0,
					0,
					{f8: col, aY: s0.aY, a1: s0.a1, ah: offset, gC: row, Y: s0.Y});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.ah, s.gC, s.f8, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.ah, s1.ah, s0.Y),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.ah, s.Y);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{f8: 1, aY: s.aY, a1: s.a1, ah: s.ah + 1, gC: s.gC + 1, Y: s.Y}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{f8: s.f8 + 1, aY: s.aY, a1: s.a1, ah: newOffset, gC: s.gC, Y: s.Y}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.ah, s.gC, s.f8, s.Y);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{f8: newCol, aY: s.aY, a1: s.a1, ah: newOffset, gC: newRow, Y: s.Y});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {f8: col, gs: problem, gC: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.gC, p.f8, p.gs);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{f8: 1, aY: _List_Nil, a1: 1, ah: 0, gC: 1, Y: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$ServerData$toHour = function (v) {
	var t = v;
	var _v1 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(t);
	if (!_v1.$) {
		var time = _v1.a;
		return A2(
			$elm$time$Time$toHour,
			A2($elm$time$Time$customZone, 3 * 60, _List_Nil),
			time);
	} else {
		return -1;
	}
};
var $author$project$Api$Object$ShopOrderDocument$withoutOverweight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'withoutOverweight', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$ServerData$documentSection = A9(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map8,
	$author$project$ServerData$Document,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$ServerData$toHour,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail($author$project$Api$Object$ShopOrderDocument$deliveryAt)),
	$author$project$Api$Object$ShopOrderDocument$groupId,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$withDefault('???'),
		$author$project$Api$Object$ShopOrderDocument$owner),
	$author$project$Api$Object$ShopOrderDocument$asap,
	$author$project$Api$Object$ShopOrderDocument$comment,
	$author$project$Api$Object$ShopOrderDocument$ecoPack,
	$author$project$Api$Object$ShopOrderDocument$withoutOverweight,
	$author$project$Api$Object$ShopOrderDocument$status);
var $author$project$Api$Object$ShopOrderItem$orderedQuantity = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.BigFloat',
	'orderedQuantity',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).o.dR);
var $author$project$Api$Object$ShopOrderItem$prepareQuantity = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.BigFloat',
	'prepareQuantity',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).o.dR);
var $author$project$Api$Object$ShopOrderItem$productId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'productId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Api$Object$ShopOrderItem$rowId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'rowId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Api$Object$ShopOrderItem$shopOrderDocumentByDocumentId = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'shopOrderDocumentByDocumentId',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Api$Object$ShopOrderItem$warehouseId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'warehouseId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$ServerData$itemSelection = A9(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map8,
	$author$project$ServerData$Item,
	$author$project$Api$Object$ShopOrderItem$rowId,
	$author$project$Api$Object$ShopOrderItem$orderedQuantity,
	$author$project$Api$Object$ShopOrderItem$prepareQuantity,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail(
		$author$project$Api$Object$ShopOrderItem$shopOrderDocumentByDocumentId($author$project$ServerData$documentSection)),
	$author$project$Api$Object$ShopOrderItem$productId,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$withDefault(''),
		$author$project$Api$Object$ShopOrderItem$comment),
	$author$project$Api$Object$ShopOrderItem$documentId,
	$author$project$Api$Object$ShopOrderItem$warehouseId);
var $author$project$Api$Object$ShopOrderItemsConnection$nodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'nodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$ServerData$itemsSelection = $author$project$Api$Object$ShopOrderItemsConnection$nodes($author$project$ServerData$itemSelection);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault = function (_default) {
	return $dillonkearns$elm_graphql$Graphql$SelectionSet$map(
		$elm$core$Maybe$withDefault(_default));
};
var $author$project$ServerData$itemsQuery = F3(
	function (warehouseId, categoryIds, day) {
		var condition = {
			d_: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				A2($elm$core$List$map, $elm$core$Maybe$Just, categoryIds)),
			cx: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(day),
			n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(warehouseId)
		};
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
			_List_Nil,
			A3(
				$author$project$Api$Query$allPackingWarehouseShopOrderItems,
				$elm$core$Basics$identity,
				{go: condition},
				$author$project$ServerData$itemsSelection));
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map4 = F5(
	function (combine, _v0, _v1, _v2, _v3) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4])),
			A5($elm$json$Json$Decode$map4, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4));
	});
var $author$project$Api$Object$ProductsConnection$nodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'nodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$ServerData$Product = F6(
	function (rowId, name, unit, quantity, categoryId, ownProduction) {
		return {M: categoryId, j: name, bH: ownProduction, k: quantity, b: rowId, b5: unit};
	});
var $author$project$ServerData$Quantity = F2(
	function (value, sustainableValue) {
		return {cF: sustainableValue, A: value};
	});
var $author$project$Api$InputObject$buildProductWarehouseQuantityCondition = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{f: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, cF: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, A: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {f: optionals____.f, cF: optionals____.cF, A: optionals____.A, n: optionals____.n};
};
var $author$project$Api$Object$Product$categoryId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'categoryId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$json$Json$Decode$map6 = _Json_map6;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map6 = F7(
	function (combine, _v0, _v1, _v2, _v3, _v4, _v5) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		var selectionFields5 = _v4.a;
		var selectionDecoder5 = _v4.b;
		var selectionFields6 = _v5.a;
		var selectionDecoder6 = _v5.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4, selectionFields5, selectionFields6])),
			A7($elm$json$Json$Decode$map6, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4, selectionDecoder5, selectionDecoder6));
	});
var $author$project$Api$Object$Product$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$ProductWarehouseQuantitiesConnection$nodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'nodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Api$Object$Product$ownProduction = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'ownProduction', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Api$InputObject$encodeProductWarehouseQuantityCondition = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'productId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.f, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'warehouseId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'value',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.A,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'sustainableValue',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.cF,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs)))
			]));
};
var $author$project$Api$InputObject$encodeProductWarehouseQuantityFilter = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'productId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.f, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'warehouseId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $author$project$Api$InputObject$encodeIntFilter)),
				_Utils_Tuple2(
				'value',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.A, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'sustainableValue',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cF, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'lastSupplyPrice',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.er, $author$project$Api$InputObject$encodeBigFloatFilter)),
				_Utils_Tuple2(
				'and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeProductWarehouseQuantityFilter))),
				_Utils_Tuple2(
				'or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Api$InputObject$encodeProductWarehouseQuantityFilter))),
				_Utils_Tuple2(
				'not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $author$project$Api$InputObject$encodeProductWarehouseQuantityFilter))
			]));
};
var $author$project$Api$Enum$ProductWarehouseQuantitiesOrderBy$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'NATURAL';
		case 1:
			return 'PRODUCT_ID_ASC';
		case 2:
			return 'PRODUCT_ID_DESC';
		case 3:
			return 'WAREHOUSE_ID_ASC';
		case 4:
			return 'WAREHOUSE_ID_DESC';
		case 5:
			return 'VALUE_ASC';
		case 6:
			return 'VALUE_DESC';
		case 7:
			return 'SUSTAINABLE_VALUE_ASC';
		case 8:
			return 'SUSTAINABLE_VALUE_DESC';
		case 9:
			return 'PRIMARY_KEY_ASC';
		default:
			return 'PRIMARY_KEY_DESC';
	}
};
var $author$project$Api$Object$Product$productWarehouseQuantitiesByProductId = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ab: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ac: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ad: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ae: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, af: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ag: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ah: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, fX: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'first', filledInOptionals____.af, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'last', filledInOptionals____.ag, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.ah, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'before',
					filledInOptionals____.ac,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'after',
					filledInOptionals____.ab,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aX;
						},
						$author$project$Api$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'orderBy',
					filledInOptionals____.fX,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ProductWarehouseQuantitiesOrderBy$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'condition', filledInOptionals____.ad, $author$project$Api$InputObject$encodeProductWarehouseQuantityCondition),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'filter', filledInOptionals____.ae, $author$project$Api$InputObject$encodeProductWarehouseQuantityFilter)
				]));
		return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'productWarehouseQuantitiesByProductId', optionalArgs____, object____, $elm$core$Basics$identity);
	});
var $author$project$Api$Object$ProductWarehouseQuantity$sustainableValue = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.BigFloat',
	'sustainableValue',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).o.dR);
var $author$project$Api$Object$ProductWarehouseQuantity$value = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.BigFloat',
	'value',
	_List_Nil,
	$author$project$Api$Scalar$unwrapCodecs($author$project$Api$ScalarCodecs$codecs).o.dR);
var $author$project$ServerData$quantitySelection = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$ServerData$Quantity, $author$project$Api$Object$ProductWarehouseQuantity$value, $author$project$Api$Object$ProductWarehouseQuantity$sustainableValue);
var $author$project$Api$Object$Product$rowId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'rowId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Api$Enum$ProductUnit$Kg = 0;
var $author$project$Api$Enum$ProductUnit$Pcs = 1;
var $author$project$Api$Enum$ProductUnit$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'KG':
				return $elm$json$Json$Decode$succeed(0);
			case 'PCS':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid ProductUnit type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Object$Product$unit = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.ProductUnit.ProductUnit', 'unit', _List_Nil, $author$project$Api$Enum$ProductUnit$decoder);
var $author$project$ServerData$productSelection = function () {
	var quantityOptionalArgs = function (n) {
		return _Utils_update(
			n,
			{
				ad: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					$author$project$Api$InputObject$buildProductWarehouseQuantityCondition(
						function (b) {
							return _Utils_update(
								b,
								{
									n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(2)
								});
						}))
			});
	};
	return A7(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map6,
		$author$project$ServerData$Product,
		$author$project$Api$Object$Product$rowId,
		$author$project$Api$Object$Product$name,
		$author$project$Api$Object$Product$unit,
		A2(
			$author$project$Api$Object$Product$productWarehouseQuantitiesByProductId,
			quantityOptionalArgs,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				A2($author$project$ServerData$Quantity, '0', '0'),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
					$elm$core$List$head,
					$author$project$Api$Object$ProductWarehouseQuantitiesConnection$nodes($author$project$ServerData$quantitySelection)))),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
			$elm$core$Maybe$withDefault(-1),
			$author$project$Api$Object$Product$categoryId),
		$author$project$Api$Object$Product$ownProduction);
}();
var $author$project$ServerData$productsSelection = $author$project$Api$Object$ProductsConnection$nodes($author$project$ServerData$productSelection);
var $author$project$Api$Object$WarehousesConnection$nodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'nodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$ServerData$Warehouse = F2(
	function (rowId, name) {
		return {j: name, b: rowId};
	});
var $author$project$Api$Object$Warehouse$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Object$Warehouse$rowId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'rowId', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$ServerData$warehouseSelection = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$ServerData$Warehouse, $author$project$Api$Object$Warehouse$rowId, $author$project$Api$Object$Warehouse$name);
var $author$project$ServerData$warehousesSelection = $author$project$Api$Object$WarehousesConnection$nodes($author$project$ServerData$warehouseSelection);
var $author$project$ServerData$initialQuery = F3(
	function (warehouseId, categoryIds, day) {
		var productOptionalArgs = function (n) {
			return _Utils_update(
				n,
				{
					fX: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
						_List_fromArray(
							[5]))
				});
		};
		var categoryOptionalArgs = function (n) {
			return _Utils_update(
				n,
				{
					fX: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
						_List_fromArray(
							[3]))
				});
		};
		return A5(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map4,
			$author$project$ServerData$Response,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail(
				A2($author$project$Api$Query$allProducts, productOptionalArgs, $author$project$ServerData$productsSelection)),
			$dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail(
				A2($author$project$Api$Query$allProductCategories, categoryOptionalArgs, $author$project$ServerData$categoriesSelection)),
			A3($author$project$ServerData$itemsQuery, warehouseId, categoryIds, day),
			$dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail(
				A2($author$project$Api$Query$allWarehouses, $elm$core$Basics$identity, $author$project$ServerData$warehousesSelection)));
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		cx: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		gm: month,
		gN: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).cx;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gm;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $author$project$Main$toMonthNumber = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gN;
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Main$toDayValue = function (v) {
	var zone = $elm$time$Time$utc;
	var year = $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, zone, v));
	var month = $elm$core$String$fromInt(
		$author$project$Main$toMonthNumber(
			A2($elm$time$Time$toMonth, zone, v)));
	var day = $elm$core$String$fromInt(
		A2($elm$time$Time$toDay, zone, v));
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[year, month, day]));
};
var $author$project$Main$init = function (flags) {
	var settings = flags.cn;
	var now = $elm$time$Time$millisToPosix(flags.fV);
	var selectedDay = $author$project$Main$toDayValue(now);
	var initialSettings = _Utils_update(
		settings,
		{eP: selectedDay});
	var initialModel = {dZ: $author$project$AppendModal$init, eq: $elm$core$Maybe$Nothing, fQ: now, dT: $elm$core$Maybe$Nothing, dm: $krisajenkins$remotedata$RemoteData$Loading, cn: initialSettings, c$: flags.c$};
	var initialCommand = A3(
		$author$project$ServerData$executeQuery,
		flags.c$,
		A3($author$project$ServerData$initialQuery, flags.cn.n, flags.cn.d_, selectedDay),
		$author$project$Main$GotResponse);
	return _Utils_Tuple2(initialModel, initialCommand);
};
var $author$project$Main$AutoUpdate = function (a) {
	return {$: 4, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {gu: processes, gI: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.gu;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.gI);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Main$subscriptions = function (_v0) {
	return A2($elm$time$Time$every, 30000, $author$project$Main$AutoUpdate);
};
var $author$project$Main$CloseModal = {$: 6};
var $author$project$Main$GotCreateItemResponse = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotItems = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$GotUpdateItemPreparedQuantityResponse = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Api$InputObject$buildShopOrderItemInput = F2(
	function (required____, fillOptionals____) {
		var optionals____ = fillOptionals____(
			{e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, al: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, I: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, k: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, T: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aB: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aC: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aK: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		return {e: optionals____.e, al: optionals____.al, l: required____.l, q: optionals____.q, aN: required____.aN, aw: optionals____.aw, H: required____.H, ax: optionals____.ax, I: optionals____.I, f: required____.f, k: optionals____.k, az: optionals____.az, b: optionals____.b, T: optionals____.T, aB: optionals____.aB, aC: optionals____.aC, n: required____.n, aK: optionals____.aK};
	});
var $author$project$Api$Object$CreateShopOrderItemPayload$clientMutationId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'clientMutationId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$ServerData$createItemSelection = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Api$Object$CreateShopOrderItemPayload$clientMutationId);
var $author$project$Api$InputObject$encodeShopOrderItemInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'documentId',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.l))),
				_Utils_Tuple2(
				'warehouseId',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.n))),
				_Utils_Tuple2(
				'productId',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.f))),
				_Utils_Tuple2(
				'orderedQuantity',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs)(input____.aN))),
				_Utils_Tuple2(
				'prepareQuantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aw,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'quantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'price',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs)(input____.H))),
				_Utils_Tuple2(
				'priceMultiplier',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ax,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'meta',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.q,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'printed',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.I, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'comment',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.e, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'deficit',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'weightMultiplier',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aK,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'supplyProductionItemId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aC, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'returnStatus',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.az,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'supplyOrderedQuantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aB,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'supplyDocumentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Api$InputObject$encodeCreateShopOrderItemInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'clientMutationId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'shopOrderItem',
				$elm$core$Maybe$Just(
					$author$project$Api$InputObject$encodeShopOrderItemInput(input____.fs)))
			]));
};
var $author$project$Api$Mutation$createShopOrderItem = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'createShopOrderItem',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'input', requiredArgs____.c, $author$project$Api$InputObject$encodeCreateShopOrderItemInput)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$ServerData$createItem = F4(
	function (productId, warehouseId, documentId, quantity) {
		var required = {
			l: documentId,
			aN: $elm$core$String$fromFloat(quantity),
			H: '0',
			f: productId,
			n: warehouseId
		};
		var optional = function (n) {
			return _Utils_update(
				n,
				{
					e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present('Добавлено вручную')
				});
		};
		var input = {
			a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			fs: A2($author$project$Api$InputObject$buildShopOrderItemInput, required, optional)
		};
		return $dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail(
			A2(
				$author$project$Api$Mutation$createShopOrderItem,
				{c: input},
				$author$project$ServerData$createItemSelection));
	});
var $author$project$Main$errorToString = function (errorData) {
	if (!errorData.$) {
		var graphqlErrors = errorData.b;
		return A2(
			$elm$core$String$join,
			'\n',
			A2(
				$elm$core$List$map,
				function ($) {
					return $.g4;
				},
				graphqlErrors));
	} else {
		var httpError = errorData.a;
		switch (httpError.$) {
			case 0:
				var url = httpError.a;
				return 'Bad url: ' + url;
			case 1:
				return 'Timeout';
			case 2:
				return 'Network error';
			case 3:
				return 'Bad status';
			default:
				return 'Bad payload';
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2(
	function (baseUrl, mutationSelectionSet) {
		return {
			e3: baseUrl,
			fP: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
			ef: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
			cN: _List_Nil,
			eK: $elm$core$Maybe$Nothing,
			dU: _List_Nil,
			cP: $elm$core$Maybe$Nothing,
			dY: false
		};
	});
var $author$project$ServerData$executeMutation = F3(
	function (token, q, msg) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg),
			A2(
				$author$project$ServerData$withAuthHeader,
				token,
				A2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest, '/graphql', q)));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Main$prepareItem = F3(
	function (rowId, v, item) {
		return _Utils_eq(item.b, rowId) ? _Utils_update(
			item,
			{aw: v}) : item;
	});
var $author$project$Main$replacePreparedItem = F3(
	function (rowId, v, res) {
		if (res.$ === 3) {
			var r = res.a;
			var prevValue = A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.aw;
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.b;
						},
						$elm$core$Basics$eq(rowId)),
					r.c3));
			return _Utils_Tuple2(
				$krisajenkins$remotedata$RemoteData$Success(
					_Utils_update(
						r,
						{
							c3: A2(
								$elm$core$List$map,
								A2($author$project$Main$prepareItem, rowId, v),
								r.c3)
						})),
				prevValue);
		} else {
			return _Utils_Tuple2(res, $elm$core$Maybe$Nothing);
		}
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Main$saveSettings = _Platform_outgoingPort(
	'saveSettings',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'byGroupId',
					$elm$json$Json$Encode$bool($.fR)),
					_Utils_Tuple2(
					'categoryIds',
					$elm$json$Json$Encode$list($elm$json$Json$Encode$int)($.d_)),
					_Utils_Tuple2(
					'hourColumnCount',
					$elm$json$Json$Encode$int($.fS)),
					_Utils_Tuple2(
					'selectedDay',
					$elm$json$Json$Encode$string($.eP)),
					_Utils_Tuple2(
					'showAppend',
					$elm$json$Json$Encode$bool($.f_)),
					_Utils_Tuple2(
					'showOnlyPending',
					$elm$json$Json$Encode$bool($.f$)),
					_Utils_Tuple2(
					'showPrepared',
					$elm$json$Json$Encode$bool($.f0)),
					_Utils_Tuple2(
					'warehouseId',
					$elm$json$Json$Encode$int($.n))
				]));
	});
var $author$project$AppendModal$updateValue = function (v) {
	return {
		fb: false,
		eX: $elm$core$String$fromFloat(v),
		A: v
	};
};
var $author$project$AppendModal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var text = msg.a;
				var _v1 = $elm$core$String$toFloat(text);
				if (!_v1.$) {
					var value = _v1.a;
					return $author$project$AppendModal$updateValue(
						A2($elm$core$Basics$max, 0, value));
				} else {
					return _Utils_update(
						model,
						{fb: true, eX: text});
				}
			case 2:
				return $author$project$AppendModal$updateValue(model.A + 1);
			case 3:
				return $author$project$AppendModal$updateValue(
					A2($elm$core$Basics$max, 1, model.A - 1));
			default:
				return model;
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Settings$toggleListItem = F2(
	function (item, items) {
		return A2($elm$core$List$member, item, items) ? A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(item),
			items) : A2($elm$core$List$cons, item, items);
	});
var $author$project$Settings$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 6:
				var n = msg.a;
				return _Utils_update(
					model,
					{fS: n});
			case 5:
				var n = msg.a;
				return _Utils_update(
					model,
					{n: n});
			case 2:
				var rowId = msg.a;
				return _Utils_update(
					model,
					{
						d_: A2($author$project$Settings$toggleListItem, rowId, model.d_)
					});
			case 0:
				return _Utils_update(
					model,
					{f0: !model.f0});
			case 4:
				return _Utils_update(
					model,
					{fR: !model.fR});
			case 1:
				return _Utils_update(
					model,
					{f_: !model.f_});
			case 3:
				return _Utils_update(
					model,
					{f$: !model.f$});
			case 7:
				var v = msg.a;
				return _Utils_update(
					model,
					{eP: v});
			default:
				return model;
		}
	});
var $author$project$Api$InputObject$buildShopOrderItemPatch = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, al: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, l: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, H: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, I: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, f: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, k: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, T: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aB: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aC: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aK: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {e: optionals____.e, al: optionals____.al, l: optionals____.l, q: optionals____.q, aN: optionals____.aN, aw: optionals____.aw, H: optionals____.H, ax: optionals____.ax, I: optionals____.I, f: optionals____.f, k: optionals____.k, az: optionals____.az, b: optionals____.b, T: optionals____.T, aB: optionals____.aB, aC: optionals____.aC, n: optionals____.n, aK: optionals____.aK};
};
var $author$project$Api$Object$UpdateShopOrderItemPayload$clientMutationId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'clientMutationId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$ServerData$updateItemPreparedQuantitySelection = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Api$Object$UpdateShopOrderItemPayload$clientMutationId);
var $author$project$Api$InputObject$encodeShopOrderItemPatch = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'rowId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'documentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.l, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'warehouseId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'productId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.f, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'orderedQuantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aN,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'prepareQuantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aw,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'quantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'price',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.H,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'priceMultiplier',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ax,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'meta',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.q,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.aQ;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'printed',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.I, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'comment',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.e, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'deficit',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'weightMultiplier',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aK,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'supplyProductionItemId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aC, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'returnStatus',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.az,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Api$Enum$ShopOrderItemReturnStatus$toString))),
				_Utils_Tuple2(
				'supplyOrderedQuantity',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aB,
					A2(
						$author$project$Api$Scalar$unwrapEncoder,
						function ($) {
							return $.o;
						},
						$author$project$Api$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'supplyDocumentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Api$InputObject$encodeUpdateShopOrderItemByRowIdInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'clientMutationId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'shopOrderItemPatch',
				$elm$core$Maybe$Just(
					$author$project$Api$InputObject$encodeShopOrderItemPatch(input____.ds))),
				_Utils_Tuple2(
				'rowId',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.b)))
			]));
};
var $author$project$Api$Mutation$updateShopOrderItemByRowId = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'updateShopOrderItemByRowId',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'input', requiredArgs____.c, $author$project$Api$InputObject$encodeUpdateShopOrderItemByRowIdInput)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$ServerData$updateItemPreparedQuantity = F2(
	function (rowId, v) {
		var input = {
			a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			b: rowId,
			ds: $author$project$Api$InputObject$buildShopOrderItemPatch(
				function (n) {
					return _Utils_update(
						n,
						{
							aw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(v)
						});
				})
		};
		return $dillonkearns$elm_graphql$Graphql$SelectionSet$nonNullOrFail(
			A2(
				$author$project$Api$Mutation$updateShopOrderItemByRowId,
				{c: input},
				$author$project$ServerData$updateItemPreparedQuantitySelection));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					var subMsg = msg.a;
					var newSettings = A2($author$project$Settings$update, subMsg, model.cn);
					var reloadItemsCmd = A3(
						$author$project$ServerData$executeQuery,
						model.c$,
						A3($author$project$ServerData$itemsQuery, newSettings.n, newSettings.d_, newSettings.eP),
						$author$project$Main$GotItems);
					if (subMsg.$ === 8) {
						var $temp$msg = $author$project$Main$CloseModal,
							$temp$model = model;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{cn: newSettings}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Main$saveSettings(newSettings),
										reloadItemsCmd
									])));
					}
				case 1:
					var subMsg = msg.a;
					switch (subMsg.$) {
						case 0:
							var $temp$msg = $author$project$Main$CloseModal,
								$temp$model = model;
							msg = $temp$msg;
							model = $temp$model;
							continue update;
						case 4:
							var _v3 = model.dT;
							if ((!_v3.$) && (_v3.a.$ === 1)) {
								var _v4 = _v3.a;
								var product = _v4.a;
								var warehouseId = _v4.b;
								var documentId = _v4.c;
								return _Utils_Tuple2(
									model,
									A3(
										$author$project$ServerData$executeMutation,
										model.c$,
										A4($author$project$ServerData$createItem, product.b, warehouseId, documentId, model.dZ.A),
										$author$project$Main$GotCreateItemResponse));
							} else {
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
						default:
							var newAppendData = A2($author$project$AppendModal$update, subMsg, model.dZ);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{dZ: newAppendData}),
								$elm$core$Platform$Cmd$none);
					}
				case 9:
					var reload = A3(
						$author$project$ServerData$executeQuery,
						model.c$,
						A3($author$project$ServerData$initialQuery, model.cn.n, model.cn.d_, model.cn.eP),
						$author$project$Main$GotResponse);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dT: $elm$core$Maybe$Nothing}),
						reload);
				case 4:
					var now = msg.a;
					var reload = A3(
						$author$project$ServerData$executeQuery,
						model.c$,
						A3($author$project$ServerData$initialQuery, model.cn.n, model.cn.d_, model.cn.eP),
						$author$project$Main$GotResponse);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fQ: now}),
						reload);
				case 3:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eq: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				case 5:
					var id = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dT: $elm$core$Maybe$Just(id)
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dT: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$none);
				case 2:
					var r = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dm: r}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var response = msg.a;
					switch (response.$) {
						case 0:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							var err = response.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										eq: $elm$core$Maybe$Just(
											$author$project$Main$errorToString(err))
									}),
								$elm$core$Platform$Cmd$none);
						default:
							var res = response.a;
							var _v6 = model.dm;
							if (_v6.$ === 3) {
								var oldRes = _v6.a;
								var newRes = _Utils_update(
									oldRes,
									{c3: res});
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											dm: $krisajenkins$remotedata$RemoteData$Success(newRes)
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							}
					}
				case 8:
					var prevId = msg.a;
					var prevValue = msg.b;
					var response = msg.c;
					switch (response.$) {
						case 0:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 1:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 2:
							var err = response.a;
							var _v8 = A3($author$project$Main$replacePreparedItem, prevId, prevValue, model.dm);
							var newRes = _v8.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										eq: $elm$core$Maybe$Just(
											$author$project$Main$errorToString(err)),
										dm: newRes
									}),
								$elm$core$Platform$Cmd$none);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				default:
					var rowId = msg.a;
					var v = msg.b;
					var _v9 = A3($author$project$Main$replacePreparedItem, rowId, v, model.dm);
					var newRes = _v9.a;
					var prevValue = _v9.b;
					var request = function () {
						if (!prevValue.$) {
							var prevV = prevValue.a;
							return A3(
								$author$project$ServerData$executeMutation,
								model.c$,
								A2($author$project$ServerData$updateItemPreparedQuantity, rowId, v),
								A2($author$project$Main$GotUpdateItemPreparedQuantityResponse, rowId, prevV));
						} else {
							return A3(
								$author$project$ServerData$executeMutation,
								model.c$,
								A2($author$project$ServerData$updateItemPreparedQuantity, rowId, v),
								A2($author$project$Main$GotUpdateItemPreparedQuantityResponse, rowId, '0'));
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dm: newRes}),
						request);
			}
		}
	});
var $author$project$Main$GotAppendModalMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$GotSettingsMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$OpenModal = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$SettingsModal = {$: 0};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$AppendModal$Change = function (a) {
	return {$: 1, a: a};
};
var $author$project$AppendModal$Close = {$: 0};
var $author$project$AppendModal$Create = {$: 4};
var $author$project$AppendModal$Decrease = {$: 3};
var $author$project$AppendModal$Increase = {$: 2};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$AppendModal$view = F2(
	function (product, model) {
		var inputAttributes = _List_fromArray(
			[
				$elm$html$Html$Attributes$type_('number'),
				$elm$html$Html$Attributes$class('w-48'),
				$elm$html$Html$Attributes$placeholder('Количество'),
				$elm$html$Html$Events$onInput($author$project$AppendModal$Change),
				$elm$html$Html$Attributes$value(model.eX),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('border-red-500 border-4', model.fb)
					])),
				$elm$html$Html$Attributes$class('appearance-none border border-gray-500 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline')
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex flex-col justify-center items-center h-screen')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mb-5 text-xl')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(product.j)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex justify-stretch')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn mr-2 w-10'),
									$elm$html$Html$Events$onClick($author$project$AppendModal$Decrease)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('-')
								])),
							A2($elm$html$Html$input, inputAttributes, _List_Nil),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn ml-2 w-10'),
									$elm$html$Html$Events$onClick($author$project$AppendModal$Increase)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('+')
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn mt-5 mr-2'),
									$elm$html$Html$Events$onClick($author$project$AppendModal$Close)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Отмена')
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('btn-blue mt-5'),
									$elm$html$Html$Events$onClick($author$project$AppendModal$Create)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Добавить')
								]))
						]))
				]));
	});
var $author$project$Settings$Close = {$: 8};
var $author$project$Settings$ToggleByGroupId = {$: 4};
var $author$project$Settings$ToggleShowAppend = {$: 1};
var $author$project$Settings$ToggleShowOnlyPending = {$: 3};
var $author$project$Settings$addDay = F2(
	function (t, count) {
		return $elm$time$Time$millisToPosix(
			((((count * 24) * 1000) * 60) * 60) + $elm$time$Time$posixToMillis(t));
	});
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Settings$SetHourColumnCount = function (a) {
	return {$: 6, a: a};
};
var $author$project$Settings$viewChangeHourColumnCountButton = F2(
	function (current, n) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn mr-2'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'btn-blue',
							_Utils_eq(current, n))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Settings$SetHourColumnCount(n))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(n))
				]));
	});
var $author$project$Settings$SelectDay = function (a) {
	return {$: 7, a: a};
};
var $author$project$Settings$toMonthNumber = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $author$project$Settings$viewChangeSelectedDay = F2(
	function (current, v) {
		var zone = $elm$time$Time$utc;
		var year = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, v));
		var month = $elm$core$String$fromInt(
			$author$project$Settings$toMonthNumber(
				A2($elm$time$Time$toMonth, zone, v)));
		var day = $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, zone, v));
		var label = A2(
			$elm$core$String$join,
			'.',
			_List_fromArray(
				[day, month]));
		var value = A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[year, month, day]));
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn mr-2'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'btn-blue',
							_Utils_eq(current, value))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Settings$SelectDay(value))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				]));
	});
var $author$project$Settings$SetWarehouseId = function (a) {
	return {$: 5, a: a};
};
var $author$project$Settings$viewChangeWarehouse = F2(
	function (current, w) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('btn mr-2'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'btn-blue',
							_Utils_eq(current, w.b))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Settings$SetWarehouseId(w.b))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(w.j)
				]));
	});
var $author$project$Settings$ToggleCategoryId = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Settings$checkbox = F3(
	function (l, v, e) {
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('select-none p-1 inline-block')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$checked(v),
							$elm$html$Html$Events$onClick(e)
						]),
					_List_Nil),
					$elm$html$Html$text(' ' + l)
				]));
	});
var $author$project$Settings$viewSettingsModalItem = F2(
	function (checkedIds, category) {
		var value = A2($elm$core$List$member, category.b, checkedIds);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('w-1/3')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Settings$checkbox,
					category.j,
					value,
					$author$project$Settings$ToggleCategoryId(category.b))
				]));
	});
var $author$project$Settings$view = F4(
	function (settings, warehouses, categories, now) {
		var dayOptions = A2(
			$elm$core$List$map,
			$author$project$Settings$addDay(now),
			A2($elm$core$List$range, -1, 2));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('py-4 px-6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-grow')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-title text-2xl font-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('День')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									A2(
										$elm$core$List$map,
										$author$project$Settings$viewChangeSelectedDay(settings.eP),
										dayOptions)),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-title text-2xl font-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Склад')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									A2(
										$elm$core$List$map,
										$author$project$Settings$viewChangeWarehouse(settings.n),
										warehouses))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-grow')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-title text-2xl font-bold')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Количество колонок с временем')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									A2(
										$elm$core$List$map,
										$author$project$Settings$viewChangeHourColumnCountButton(settings.fS),
										A2($elm$core$List$range, 1, 10))),
									(settings.n === 1) ? A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-title text-2xl font-bold')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Можно докладывать')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('checkbox'),
													$elm$html$Html$Attributes$checked(settings.f_),
													$elm$html$Html$Events$onClick($author$project$Settings$ToggleShowAppend)
												]),
											_List_Nil)
										])) : A2($elm$html$Html$div, _List_Nil, _List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-title text-lg font-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Не показывать отправленные')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('checkbox'),
															$elm$html$Html$Attributes$checked(settings.f$),
															$elm$html$Html$Events$onClick($author$project$Settings$ToggleShowOnlyPending)
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('pl-2')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-title text-lg font-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('По маршрутам')
														])),
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('checkbox'),
															$elm$html$Html$Attributes$checked(settings.fR),
															$elm$html$Html$Events$onClick($author$project$Settings$ToggleByGroupId)
														]),
													_List_Nil)
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-title text-2xl font-bold mt-2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Показывать из категорий')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex flex-wrap')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Settings$viewSettingsModalItem(settings.d_),
						categories)),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn-blue mt-2'),
							$elm$html$Html$Events$onClick($author$project$Settings$Close)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Сохранить')
						]))
				]));
	});
var $author$project$Main$HideLastError = {$: 3};
var $author$project$Main$viewLastError = function (err) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('absolute top-0 right-0 bg-red-700 text-white px-2 py-1'),
				$elm$html$Html$Events$onClick($author$project$Main$HideLastError)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(err)
			]));
};
var $author$project$Main$nothing = $elm$html$Html$text('');
var $author$project$Main$viewMaybe = F2(
	function (view_, value) {
		if (!value.$) {
			var v = value.a;
			return view_(v);
		} else {
			return $author$project$Main$nothing;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Main$itemPrepared = function (item) {
	return _Utils_eq(item.aN, item.aw);
};
var $author$project$Main$haveNotPreapredItems = F3(
	function (items, inHour, hour) {
		var firstNotPrepared = A2(
			$elm_community$list_extra$List$Extra$find,
			function (i) {
				return A2(inHour, hour, i) && (!$author$project$Main$itemPrepared(i));
			},
			items);
		return $elm_community$maybe_extra$Maybe$Extra$isJust(firstNotPrepared);
	});
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Main$preparedInHour = F3(
	function (items, hour, product) {
		if (!hour.$) {
			var h = hour.a;
			var firstNotPreparedItem = A2(
				$elm_community$list_extra$List$Extra$find,
				function (i) {
					return _Utils_eq(i.f, product.b) && (_Utils_eq(i.cR.fc, h) && (!$author$project$Main$itemPrepared(i)));
				},
				items);
			var firstItem = A2(
				$elm_community$list_extra$List$Extra$find,
				function (i) {
					return _Utils_eq(i.f, product.b) && _Utils_eq(i.cR.fc, h);
				},
				items);
			if (!firstNotPreparedItem.$) {
				return -1;
			} else {
				return A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					1,
					$elm$core$Basics$always(0),
					firstNotPreparedItem) + A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					1,
					$elm$core$Basics$always(0),
					firstItem);
			}
		} else {
			return 99;
		}
	});
var $elm$core$List$sortBy = _List_sortBy;
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $author$project$Main$cellClass = $elm$html$Html$Attributes$class('border px-4 py-2');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Main$viewTableHeader = F3(
	function (settings, hourColumnCount, hours) {
		var cn = 'w-1/' + $elm$core$String$fromInt(hourColumnCount + 1);
		var viewCell = function (c) {
			return A2(
				$elm$html$Html$th,
				_List_fromArray(
					[
						$author$project$Main$cellClass,
						$elm$html$Html$Attributes$class(cn)
					]),
				_List_fromArray(
					[c]));
		};
		var viewHour = A2(
			$elm$core$Basics$composeL,
			A2($elm$core$Basics$composeL, viewCell, $elm$html$Html$text),
			$elm$core$String$fromInt);
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				viewCell(settings),
				A2($elm$core$List$map, viewHour, hours)));
	});
var $author$project$Main$AppendModal = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Main$formatProductUnit = function (u) {
	if (!u) {
		return 'кг';
	} else {
		return 'шт';
	}
};
var $author$project$Main$justRemove = F2(
	function (pattern, s) {
		var _v0 = $elm$regex$Regex$fromString(pattern);
		if (!_v0.$) {
			var regex = _v0.a;
			return A3(
				$elm$regex$Regex$replace,
				regex,
				$elm$core$Basics$always(''),
				s);
		} else {
			return s;
		}
	});
var $author$project$Main$normalizeQuantity = function (v) {
	var s = v;
	return A2(
		$author$project$Main$justRemove,
		'\\.$',
		A2($author$project$Main$justRemove, '[0]*$', s));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$td = _VirtualDom_node('td');
var $author$project$Main$isEmpty = $elm$core$Basics$neq('');
var $author$project$Main$PrepareItem = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Main$viewDot = function (cn) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('absolute rounded-lg top-0 -mt-2 w-4 h-4 border border-white'),
				$elm$html$Html$Attributes$class(cn)
			]),
		_List_Nil);
};
var $author$project$Main$viewEcoDot = function (v) {
	return v ? $author$project$Main$viewDot('bg-blue-500 right-0 -mr-1') : $elm$html$Html$text('');
};
var $author$project$Main$viewWithoutOverweightDot = function (v) {
	return v ? $author$project$Main$viewDot('bg-red-600 right-auto left-0 -ml-1') : $elm$html$Html$text('');
};
var $author$project$Main$viewItem = function (i) {
	var clickMsg = $author$project$Main$itemPrepared(i) ? A2($author$project$Main$PrepareItem, i.b, '0') : A2($author$project$Main$PrepareItem, i.b, i.aN);
	var btn = $author$project$Main$itemPrepared(i) ? 'btn' : ((i.cR.R === '') ? 'btn' : ('btn-' + i.cR.R));
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(btn),
				$elm$html$Html$Attributes$class('px-4 py-3 m-1 relative'),
				$elm$html$Html$Events$onClick(clickMsg),
				$elm$html$Html$Attributes$title(
				$elm$core$String$fromInt(i.l))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Main$normalizeQuantity(i.aN)),
				$author$project$Main$viewWithoutOverweightDot(i.cR.aM),
				$author$project$Main$viewEcoDot(i.cR.at)
			]));
};
var $author$project$Main$viewHourCell = F5(
	function (appendButton, cn, allItems, inHour, h) {
		var viewComment = function (i) {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(i.l) + ': ')
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Main$normalizeQuantity(i.aN) + ': ')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(i.e)
							]))
					]));
		};
		var items = A2(
			$elm$core$List$filter,
			inHour(h),
			allItems);
		var itemCells = A2(
			$elm$core$List$cons,
			appendButton(h),
			A2($elm$core$List$map, $author$project$Main$viewItem, items));
		var comments = A2(
			$elm$core$List$map,
			viewComment,
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeL,
					$author$project$Main$isEmpty,
					function ($) {
						return $.e;
					}),
				items));
		return A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$author$project$Main$cellClass,
					$elm$html$Html$Attributes$class(cn)
				]),
			_Utils_ap(
				itemCells,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-gray-600 text-xs')
							]),
						comments)
					])));
	});
var $author$project$Main$viewTableRow = F6(
	function (allItems, hours, showPrepared, showAppend, inHour, product) {
		var productItems = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.f;
				},
				$elm$core$Basics$eq(product.b)),
			allItems);
		var productCell = _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-xs text-gray-500 flex')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(product.b + 1000))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-grow')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mr-2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Остаток: ' + $author$project$Main$normalizeQuantity(product.k.A))
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(
								'Резерв: ' + $author$project$Main$normalizeQuantity(product.k.cF))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-bold ml-2')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Main$formatProductUnit(product.b5))
							]))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-lg')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(product.j)
					]))
			]);
		var items = showPrepared ? productItems : A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $author$project$Main$itemPrepared, $elm$core$Basics$not),
			productItems);
		var firstItem = $elm$core$List$head(allItems);
		var cn = 'w-1/' + $elm$core$String$fromInt(
			$elm$core$List$length(hours) + 1);
		var appendButton = function (hour) {
			var firstItemDoc = A2(
				$elm_community$list_extra$List$Extra$find,
				function (i) {
					return _Utils_eq(i.cR.fc, hour);
				},
				allItems);
			var _v0 = _Utils_Tuple3(firstItem, firstItemDoc, showAppend);
			if (((!_v0.a.$) && (!_v0.b.$)) && _v0.c) {
				var item = _v0.a.a;
				var fd = _v0.b.a;
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn px-4 py-3 m-1'),
							$elm$html$Html$Events$onClick(
							$author$project$Main$OpenModal(
								A3($author$project$Main$AppendModal, product, item.n, fd.l)))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('+')
						]));
			} else {
				return $author$project$Main$nothing;
			}
		};
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							$author$project$Main$cellClass,
							$elm$html$Html$Attributes$class(cn)
						]),
					productCell),
				A2(
					$elm$core$List$map,
					A4($author$project$Main$viewHourCell, appendButton, cn, items, inHour),
					hours)));
	});
var $author$project$Main$viewTable = F8(
	function (settings, showPrepared, showAppend, showOnlyPending, hourColumnCount, categoryIds, byGroupId, res) {
		var productIds = A2(
			$elm$core$List$map,
			function ($) {
				return $.f;
			},
			res.c3);
		var products = showAppend ? A2(
			$elm$core$List$filter,
			function (p) {
				return A2($elm$core$List$member, p.M, categoryIds) && p.bH;
			},
			res.gv) : A2(
			$elm$core$List$filter,
			function (p) {
				return A2($elm$core$List$member, p.b, productIds);
			},
			res.gv);
		var items = showOnlyPending ? A2(
			$elm$core$List$filter,
			function (i) {
				return !i.cR.E;
			},
			res.c3) : res.c3;
		var inHour = F2(
			function (v, i) {
				if (byGroupId) {
					var _v0 = i.cR.S;
					if (!_v0.$) {
						var id = _v0.a;
						return _Utils_eq(id, v);
					} else {
						return false;
					}
				} else {
					return _Utils_eq(i.cR.fc, v);
				}
			});
		var allHours = byGroupId ? $elm$core$List$sort(
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$filterMap,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.cR;
						},
						function ($) {
							return $.S;
						}),
					items))) : $elm$core$List$sort(
			$elm_community$list_extra$List$Extra$unique(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.cR;
						},
						function ($) {
							return $.fc;
						}),
					items)));
		var hours = showPrepared ? allHours : A2(
			$elm$core$List$filter,
			A2($author$project$Main$haveNotPreapredItems, res.c3, inHour),
			allHours);
		var limitedHours = A2($elm$core$List$take, hourColumnCount, hours);
		var sortedProducts = A2(
			$elm$core$List$sortBy,
			A2(
				$author$project$Main$preparedInHour,
				res.c3,
				$elm$core$List$head(limitedHours)),
			products);
		var content = ($elm_community$maybe_extra$Maybe$Extra$isJust(
			$elm$core$List$head(res.c3)) || showAppend) ? A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table-fixed w-full mt-16')
				]),
			A2(
				$elm$core$List$map,
				A5($author$project$Main$viewTableRow, res.c3, limitedHours, showPrepared, showAppend, inHour),
				sortedProducts)) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('mt-16 px-4')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Ничего не найдено.')
				]));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					content,
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table-fixed w-full fixed top-0 left-0 bg-white')
						]),
					_List_fromArray(
						[
							A3($author$project$Main$viewTableHeader, settings, hourColumnCount, limitedHours)
						]))
				]));
	});
var $author$project$Settings$ToggleShowPrepared = {$: 0};
var $author$project$Settings$viewToggleShowPrepared = function (m) {
	return A3($author$project$Settings$checkbox, 'Все', m.f0, $author$project$Settings$ToggleShowPrepared);
};
var $author$project$Main$view = function (model) {
	var settings = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex justify-between')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn'),
						$elm$html$Html$Events$onClick(
						$author$project$Main$OpenModal($author$project$Main$SettingsModal))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Настроки')
					])),
				A2(
				$elm$html$Html$map,
				$author$project$Main$GotSettingsMsg,
				$author$project$Settings$viewToggleShowPrepared(model.cn))
			]));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2($author$project$Main$viewMaybe, $author$project$Main$viewLastError, model.eq),
				function () {
				var _v0 = model.dm;
				switch (_v0.$) {
					case 0:
						return $elm$html$Html$text('Initialising.');
					case 1:
						return $elm$html$Html$text('Loading.');
					case 2:
						var err = _v0.a;
						return $elm$html$Html$text(
							'Error: ' + $author$project$Main$errorToString(err));
					default:
						var res = _v0.a;
						var _v1 = model.dT;
						if (!_v1.$) {
							if (!_v1.a.$) {
								var _v2 = _v1.a;
								return A2(
									$elm$html$Html$map,
									$author$project$Main$GotSettingsMsg,
									A4($author$project$Settings$view, model.cn, res.hn, res.gR, model.fQ));
							} else {
								var _v3 = _v1.a;
								var product = _v3.a;
								var warehouseId = _v3.b;
								var documentId = _v3.c;
								return A2(
									$elm$html$Html$map,
									$author$project$Main$GotAppendModalMsg,
									A2($author$project$AppendModal$view, product, model.dZ));
							}
						} else {
							return A8($author$project$Main$viewTable, settings, model.cn.f0, model.cn.f_, model.cn.f$, model.cn.fS, model.cn.d_, model.cn.fR, res);
						}
				}
			}()
			]));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{g$: $author$project$Main$init, hi: $author$project$Main$subscriptions, hl: $author$project$Main$update, hm: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (settings) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (nowMillis) {
							return $elm$json$Json$Decode$succeed(
								{fV: nowMillis, cn: settings, c$: token});
						},
						A2($elm$json$Json$Decode$field, 'nowMillis', $elm$json$Json$Decode$int));
				},
				A2(
					$elm$json$Json$Decode$field,
					'settings',
					A2(
						$elm$json$Json$Decode$andThen,
						function (warehouseId) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (showPrepared) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (showOnlyPending) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (showAppend) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (selectedDay) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (hourColumnCount) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (categoryIds) {
																			return A2(
																				$elm$json$Json$Decode$andThen,
																				function (byGroupId) {
																					return $elm$json$Json$Decode$succeed(
																						{fR: byGroupId, d_: categoryIds, fS: hourColumnCount, eP: selectedDay, f_: showAppend, f$: showOnlyPending, f0: showPrepared, n: warehouseId});
																				},
																				A2($elm$json$Json$Decode$field, 'byGroupId', $elm$json$Json$Decode$bool));
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'categoryIds',
																			$elm$json$Json$Decode$list($elm$json$Json$Decode$int)));
																},
																A2($elm$json$Json$Decode$field, 'hourColumnCount', $elm$json$Json$Decode$int));
														},
														A2($elm$json$Json$Decode$field, 'selectedDay', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'showAppend', $elm$json$Json$Decode$bool));
										},
										A2($elm$json$Json$Decode$field, 'showOnlyPending', $elm$json$Json$Decode$bool));
								},
								A2($elm$json$Json$Decode$field, 'showPrepared', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'warehouseId', $elm$json$Json$Decode$int))));
		},
		A2(
			$elm$json$Json$Decode$field,
			'token',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
						A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
					])))))(0)}});}(this));