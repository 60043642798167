import './main.css';
import './tailwind.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

const SETTINGS_KEY = 'settings-v1';

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    nowMillis: +new Date,
    token: location.hash.substr(1),
    settings: {
      categoryIds: [],
      byGroupId: false,
      showPrepared: false,
      showAppend: false,
      showOnlyPending: false,
      hourColumnCount: 3,
      warehouseId: 2,
      ...JSON.parse(localStorage.getItem(SETTINGS_KEY)),
      selectedDay: '',
    },
  },
});

app.ports.saveSettings.subscribe(v => {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(v));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
